import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
const antifraudPolicy = ({ classes, data }) => {
  var content = [];
  var Images = data.allStrapiImages.edges;
  var bwImage = Images[0];
  var clrImage = Images[1];
  var sorted = data.allStrapiEntities.group.sort(
    (a, b) => a.edges[0].node.sequence - b.edges[0].node.sequence
  );
  var rightContent = [],
    leftContent = [];
  var antiFraudcontent = sorted.map((document, ind) => {
    content = (
      <Card
        blog
        className={classes.customCard}
        style={{ marginTop: `10px` }}
        key={`anti-fraud-content-${ind}`}
      >
        <GridContainer>
          <GridItem md={12} sm={12} className={classes.textCenter}>
            <CardBody>
              {document.edges.map((cardBodyContent, i) => {
                if (cardBodyContent.node.displayType === `text`) {
                  var description = cardBodyContent.node.summary.split(`\n`);
                  return (
                    <GridItem xs={12} sm={12} md={12} key={`card-body-${i}`}>
                      <h4
                        className={classes.cardTitle}
                        style={{ paddingBottom: 20, textAlign: `left` }}
                      >
                        {cardBodyContent.node.title}
                      </h4>
                      <p style={{ textAlign: `left` }}>{description}</p>
                    </GridItem>
                  );
                }
                if (cardBodyContent.node.displayType === `list`) {
                  var description1 = (
                    <ul style={{ listStyle: `none`, textAlign: `left` }}>
                      {cardBodyContent.node.summary
                        .split(`\n\n`)
                        .map((e, i) => (
                          <li key={i}>
                            <p>{e}</p>
                          </li>
                        ))}
                    </ul>
                  );
                  return (
                    <GridItem xs={12} sm={12} md={12} key={`card-body-${i}`}>
                      <h4
                        className={classes.cardTitle}
                        style={{
                          paddingBottom: 20,
                          paddingTop: 10,
                          textAlign: `left`
                        }}
                      >
                        {cardBodyContent.node.title}
                      </h4>
                      <div>{description1}</div>
                    </GridItem>
                  );
                }
              })}
            </CardBody>
          </GridItem>
        </GridContainer>
      </Card>
    );
    if (ind < 8) {
      rightContent.push(content);
    } else {
      leftContent.push(content);
    }

    if (ind === sorted.length - 1) {
      return (
        <GridItem
          md={10}
          sm={10}
          className={`${classes.textCenter} ${classes.mlAuto} ${classes.mrAuto}`}
          key={`grid-content-${ind}`}
        >
          <GridContainer>
            <GridItem
              md={6}
              sm={12}
              className={`${classes.textCenter} ${classes.mlAuto} ${classes.mrAuto}`}
            >
              {rightContent}
            </GridItem>
            <GridItem
              md={6}
              sm={12}
              className={`${classes.textCenter} ${classes.mlAuto} ${classes.mrAuto}`}
            >
              {leftContent}
            </GridItem>
          </GridContainer>
        </GridItem>
      );
    }
  });

  return (
    <Layout
      image1={
        bwImage &&
        bwImage.node &&
        bwImage.node.content &&
        bwImage.node.content.childImageSharp.fluid
      }
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
        <title>AntiFraud Policy | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href="https://www.starhealth.in/antifraud-policy"
          />
          <meta
            name="description"
            content="Explore Our AntiFraud Policy Details."
          />
          <meta
            name="keywords"
            content="Star Health And Allied Insurance Company Limited, Star Health Care, Star Health Family Insurance, Star Health Insurance, Star Health Insurance Company, Star Health Insurance India, Star Health Insurance Plans, Star Health Insurance Policies, Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy"
          />
          <meta name="title" content="AntiFraud Policy | StarHealth.in" />
          <meta name="twitter:title" content="AntiFraud Policy | StarHealth.in" />
          <meta property="og:title" content="AntiFraud Policy | StarHealth.in" />
          <meta property="og:description" content="Explore Our AntiFraud Policy Details." />
          <meta property="og:url" content="https://www.starhealth.in/antifraud-policy"/>
          <meta name="AntiFraud Policy | StarHealth.in" />
          <meta name="twitter:description" content="Explore Our AntiFraud Policy Details." />
          <meta property="twitter:url" content="https://www.starhealth.in/antifraud-policy" />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.team}>
          <div
            className={`${classes.container} ${classes.textCenter} ${classes.mlAuto} ${classes.mrAuto}`}
          >
            <GridContainer>
              <GridItem
                md={12}
                sm={12}
                className={classes.textCenter}
                style={{ paddingBottom: `20px` }}
              >
                <h2 className={classes.title}>Anti-Fraud Policy</h2>
              </GridItem>
              {antiFraudcontent}
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
antifraudPolicy.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};
export default withStyles(customStaticPageStyle)(antifraudPolicy);

export const antiFraudPolicyQuery = graphql`
  query antiFraudPolicy {
    allStrapiEntities(filter: { category: { eq: "antiFraudPolicy" } }) {
      group(field: sequence) {
        edges {
          node {
            id
            title
            summary
            description
            category
            displayType
            sequence
            subSequence
            url
          }
        }
      }
    }
    allStrapiImages(
      filter: { section: { category: { eq: "anti_fraud_policy" } } }
    ) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
